
import { Options, Vue } from "vue-class-component";

@Options({
  data: () => ({
    shoots: [
      {
        type: "engagement",
        number: 3,
      },
      {
        type: "wedding",
        number: 440,
      },
      {
        type: "olly",
        number: 1,
      },
    ],
  }),
})
export default class App extends Vue { }
