import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/engagement',
    name: 'engagement',
    component: () => import(/* webpackChunkName: "engagement" */ '../views/EngagementView.vue')
  },
  {
    path: '/wedding',
    name: 'wedding',
    component: () => import(/* webpackChunkName: "wedding" */ '../views/WeddingView.vue')
  },
  {
    path: '/olly',
    name: 'olly',
    component: () => import(/* webpackChunkName: "olly" */ '../views/OllyView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
