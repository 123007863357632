import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shoots, (shoot, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: i,
        class: "shoot"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", null, _toDisplayString(shoot.type), 1),
          _createVNode(_component_router_link, {
            to: shoot.type,
            class: "button secondary"
          }, {
            default: _withCtx(() => [
              _createTextVNode("View")
            ]),
            _: 2
          }, 1032, ["to"])
        ]),
        _createElementVNode("img", {
          loading: "lazy",
          src: `dist/img/` + shoot.type + `/Moran` + shoot.number + `.webp`,
          class: "img-responsive"
        }, null, 8, _hoisted_3)
      ]))
    }), 128))
  ]))
}